import Button from "@components/elements/button"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"

export const OnboardingTraining = ({ handleClick, microsite }) => {
  const { color } = useTheme()
  const product = microsite ? "PowerMetrics" : "Klipfolio"

  return (
    <Flex alignItems="flex-start" gap="1rem">
      <Heading id="onboarding" as="h2" margin="2rem 0 1rem">
        Onboarding & Training
      </Heading>
      <Paragraph>
        Get your team up and running fast with customized onboarding and
        training delivered by our Professional Services experts. We tailor the
        experience to meet the needs of technical and non-technical users and
        ensure everyone gets the most out of {product}.
      </Paragraph>
      <Paragraph margin="0 0 1rem">
        We recommend Onboarding & Training to all new {product} customers.
        {!microsite && (
          <>
            <br />
            <b>Price: $220 USD/hr</b> (minimum 5 hrs)
          </>
        )}
      </Paragraph>
      <Button
        onClick={handleClick}
        notice={false}
        background={color.indigo600}
        arrow
      >
        Learn more
      </Button>
    </Flex>
  )
}

OnboardingTraining.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}
