import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Testimonial } from "@components/common/testimonial"

export const ServicesTestimonial = ({ microsite }) => {
  const { image } = useStaticQuery(graphql`
    query ServicesTestimonial {
      image: directusFiles(
        directus_id: { eq: "74da0e9a-e8a9-4342-b109-6fedc1f341a7" }
      ) {
        directus_id
        cdn
        id
        placeholder
        title
      }
    }
  `)

  const testimonial = microsite
    ? `“The Professional Services Team has been fantastic. Not only
  did they help us achieve our goals, they got everything implemented
  quickly and with quality.”`
    : `“Klipfolio’s Professional Services Team has been fantastic. Not only did
  they help us achieve our goals, they got everything implemented quickly
  and with quality.”`

  return (
    <Testimonial testimonial={testimonial} name="Brian Dainis" title="CEO at Curotec" image={image} />
  )
}

ServicesTestimonial.propTypes = {
  microsite: PropTypes.bool,
}
