import Button from "@components/elements/button"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import Image from "@components/image"
import Div from "@components/elements/div"
import { graphql, useStaticQuery } from "gatsby"
import Anchor from "@components/elements/anchor"

export const HireAPartner = ({ handleClick, microsite }) => {
  const { color } = useTheme()
  const { image } = useStaticQuery(graphql`
    query HireAPartner {
      image: directusFiles(
        directus_id: { eq: "33626ec2-69fe-4bfa-aa79-53201353bb45" }
      ) {
        directus_id
        cdn
        id
        placeholder
        title
      }
    }
  `)

  return (
    <Div padding="8rem 0" background={color.indigo100}>
      <Flex gap="1rem" textContainer>
        <Image height={80} width={80} objectFit="scale-down" file={image} />
        <Heading margin="1rem 0" as="h2">
          Hire a partner
        </Heading>
        {microsite ? (
          <>
            <Paragraph>
              A trusted PowerMetrics Partner will help you plan, deploy and
              manage your PowerMetrics account.
            </Paragraph>
            <Paragraph margin="0 0 1rem">
              Want to work in a specific language or time zone? We’ll help you
              choose the right partner from our international group of
              PowerMetrics experts.
            </Paragraph>
          </>
        ) : (
          <>
            <Paragraph>
              A certified Klipfolio Partner will help you plan, deploy and
              manage your Klipfolio accounts.
            </Paragraph>
            <Paragraph margin="0 0 1rem">
              Want to work in a specific language or time zone? We’ll help you
              choose the right partner from our international group of certified
              Klipfolio experts.
            </Paragraph>
          </>
        )}
        <Flex flexFlow="row" alignItems="center" gap="1rem">
          <Button
            onClick={handleClick}
            notice={false}
            background={color.indigo600}
            arrow
          >
            Contact us
          </Button>
          {!microsite && (
            <Paragraph fontSize="1.1rem">
              or browse our&nbsp;
              <Anchor link="https://www.klipfolio.com/partners/directory">
                Partner Directory
              </Anchor>
            </Paragraph>
          )}
        </Flex>
      </Flex>
    </Div>
  )
}

HireAPartner.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}
