import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import Span from "@components/elements/span"
import Heading from "@components/elements/heading"
import Button from "@components/elements/button"
import Image from "@components/image"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { useTheme } from "styled-components"

export const ServicesHeader = ({ microsite }) => {
  const { gradient } = useGradient()
  const { color, shadow } = useTheme()
  const product = microsite ? "PowerMetrics" : "Klipfolio"
  const { image } = useStaticQuery(graphql`
    query ServicesHeader {
      image: directusFiles(
        directus_id: { eq: "51d5dad8-6ad4-4336-bd5e-5d3dbe446f32" }
      ) {
        directus_id
        cdn
        id
        placeholder
        title
      }
    }
  `)

  return (
    <Div overflow="hidden" padding="10rem 0 6rem" paddingSm="8rem 0 4rem" position="relative">
      <Grid
        columns="1fr 1fr"
        columnsMd="1fr"
        gap="4rem"
        gapMd="2rem"
        container
        position="relative"
        zIndex={1}
      >
        <Flex alignItems="flex-start" gap="1rem">
          <Span fontWeight={700} color={color.indigo400}>
            {product} Services
          </Span>
          <Heading>Save time and get great results.</Heading>
          <Paragraph lineHeight={1.5}>
            Our data experts will not only get your projects up and running
            fast, they’ll train your team and deliver a customized solution that
            aligns with your business goals.
          </Paragraph>
          <Paragraph lineHeight={1.5} margin="0 0 1rem">
            Explore our services and get in touch. We’re here to help.
          </Paragraph>
          <Button
            href="#onboarding"
            notice={false}
            background={color.indigo600}
            arrow
          >
            Explore services
          </Button>
        </Flex>
        <Flex
          background="white"
          borderRadius="1rem"
          overflow="hidden"
          flexFlow="row"
          boxShadow={shadow.soft}
        >
          <Image file={image} />
        </Flex>
      </Grid>
      <HighGradient src={gradient.cdn} loading="eager" />
    </Div>
  )
}

ServicesHeader.propTypes = {
  microsite: PropTypes.bool,
}
