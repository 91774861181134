import React, { useCallback } from "react"
import Layout from "@components/layout"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Modal from "@components/elements/modal"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { FormWrapper } from "@components/styles/form-wrapper"
import { OnboardingTraining } from "@components/pages/services/sections/onboarding-training"
import { ImplementationServices } from "@components/pages/services/sections/implementation-services"
import { DedicatedDataHero } from "@components/pages/services/sections/dedicated-data-hero"
import { SixtyDayPOC } from "@components/pages/services/sections/60-day-poc"
import { HireAPartner } from "@components/pages/services/sections/hire-a-partner"
import { ServicesTestimonial } from "@components/pages/services/sections/services-testimonial"
import { ServicesHeader } from "@components/pages/services/sections/services-header"

const MicrositeServicesPage = () => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Layout
      title="PowerMetrics Services - Get up and running quickly. Our team is here to help."
      description={`Save time and get great results with PowerMetrics' expert services. Quick onboarding, team training, and customized implementation for superior business outcomes.`}
      titleExtension=""
      marginless
      fullWidth
      microsite
      hideOverflow
    >
      <Modal show={modalOpen} setModalState={setModalOpen}>
        <FormWrapper>
          <Form>
            <HubspotForm
              portalId="7477725"
              formId="344ede1a-eca1-4a2b-b1f0-9b4734bffcb0"
            />
          </Form>
        </FormWrapper>
      </Modal>
      <ServicesHeader microsite />
      <Div textContainer margin="0 auto 10rem">
        <ServicesTestimonial microsite />
        <Flex gap="4rem">
          <SixtyDayPOC handleClick={handleClick} microsite />
          <OnboardingTraining handleClick={handleClick} microsite />
          <DedicatedDataHero handleClick={handleClick} microsite />
          <ImplementationServices handleClick={handleClick} microsite />
        </Flex>
      </Div>
      <HireAPartner handleClick={handleClick} microsite />
    </Layout>
  )
}

export default MicrositeServicesPage
