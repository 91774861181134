import Button from "@components/elements/button"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"

export const DedicatedDataHero = ({ handleClick, microsite }) => {
  const { color } = useTheme()

  return (
    <Flex alignItems="flex-start" gap="1rem">
      <Heading as="h2" margin="4rem 0 1rem">
        Dedicated Data Hero
      </Heading>
      {microsite ? (
        <>
          <Paragraph>
            Learn how to connect to data warehouses, set up and work with
            semantic layers, navigate 3rd party APIs and build data feeds, data
            sources, metrics and visualizations. Our experts will also guide you
            in the best practices for account architecture, setup and
            organization.
          </Paragraph>
          <Paragraph margin="0 0 1rem">
            The Data Hero monthly subscription includes 2 hours of customized
            sessions each month and Priority Support. Service hours cannot be
            carried over from one month to the next.
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph>
            Level up your team&apos;s skills with monthly one-on-one training.
            Get expert implementation advice with a program tailored to your
            business and project goals.
          </Paragraph>
          <Paragraph>
            Learn how to navigate 3rd party APIs and build data feeds, data
            sources, metrics and visualizations. Our experts will also guide you
            in the best practices for account architecture, setup and
            organization.
          </Paragraph>
          <Paragraph margin="0 0 1rem">
            <b>Price: $379/mth</b> as a monthly subscription (minimum 3 months)
            - includes 2 hours of customized sessions each month and Priority
            Support. Service hours cannot be carried over from one month to the
            next.
          </Paragraph>
        </>
      )}
      <Button
        onClick={handleClick}
        notice={false}
        background={color.indigo600}
        arrow
      >
        Learn more
      </Button>
    </Flex>
  )
}

DedicatedDataHero.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}
